import { useEffect } from "react"
import { useNavigate } from "utils/router"

/**
 * REDIRECT RULES
 * TLDR; Redirects must follow a DAG
 *
 * DAG
 * - no auth present: Inside Auth Wall -> Outside Auth Wall
 * - some auth present: Outside Auth Wall -> Inside Auth Wall
 *
 * TODO: check presence of auth inside this component
 */

type Props = {
  path: string
  replace?: boolean
  // eslint-disable-next-line
  acknowledgeRedirectRules: boolean // see https://www.notion.so/beamco/Redirects-66d9a48f81754bb28e551f22073eab9d
}

export default function Redirect({ path, replace }: Props) {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(path, { replace: replace ?? false })
  }, [navigate, path, replace])

  return null
}
